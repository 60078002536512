import { ReactElement } from 'react';
import { FormattedMessage, type MessageDescriptor } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

interface FeatureItemProps {
  feature: {
    id: number;
    accentColor: string;
    isReverse: boolean;
    imgSrc: string;
    messages: {
      title: MessageDescriptor;
      accent: MessageDescriptor;
      description: MessageDescriptor;
    };
  };
}

const Feature = ({ feature }: FeatureItemProps): ReactElement => (
  <div className={`items-center lg:flex ${feature.isReverse ? 'lg:flex-row-reverse' : ''}`}>
    <div className={`mb-8 sm:mb-4 lg:mb-0 lg:max-w-112 lg:px-11 ${feature.isReverse ? 'lg:ml-8' : 'lg:mr-8'}`}>
      <h3 className="mb-3 text-8 font-semibold leading-snug md:mb-4 md:text-10 lg:leading-tight">
        <FormattedMessage
          {...feature.messages.title}
          values={{
            br: <br className="block sm:hidden lg:block" />,
            accent: (
              <span style={{ color: feature.accentColor }}>
                <FormattedMessage {...feature.messages.accent} />
              </span>
            ),
          }}
        />
      </h3>
      <p>
        <FormattedMessage {...feature.messages.description} />
      </p>
    </div>
    <OptimizedImage
      className="w-full max-w-[640px] scale-110 sm:-ml-[2%] sm:scale-100 lg:-ml-0 lg:pt-10"
      src={feature.imgSrc}
      width={640}
      height={480}
      alt=""
    />
  </div>
);

export { Feature };
