import { ReactElement } from 'react';
import { defineMessages } from 'react-intl';
import { Feature } from './feature';

const featureMessages = {
  cleanup: defineMessages({
    title: {
      defaultMessage: 'Simple Mac {br}{accent}{br} and file organization',
    },
    accent: {
      defaultMessage: 'cleanup',
    },
    description: {
      defaultMessage:
        'Delete large amounts of unwanted data and manage your important files. Delete unwanted system files, corrupted downloads, and 20 other types of unwanted data, making cleaning your Mac easier and safer than ever.',
    },
  }),
  performance: defineMessages({
    title: {
      defaultMessage: 'Improved Mac {accent}',
    },
    accent: {
      defaultMessage: 'performance and speed',
    },
    description: {
      defaultMessage:
        "Don't let unexpected performance degradation kill your productivity. Keep your Mac running at peak speed with just one button. Perform recommended maintenance tasks, disable unnecessary background apps, and manage login items, all from one place.",
    },
  }),
  protection: defineMessages({
    title: {
      defaultMessage: 'Virus Removal and {accent}',
    },
    accent: {
      defaultMessage: 'privacy',
    },
    description: {
      defaultMessage:
        'CleanMyMac features advanced antivirus protection to neutralize all threats with privacy as a top priority; scan your Mac for viruses and manage application permissions with ease. Your privacy is important and needs to be managed quickly and easily.',
    },
  }),
  applications: defineMessages({
    title: {
      defaultMessage: 'Easily manage {accent} from a single location',
    },
    accent: {
      defaultMessage: 'applications',
    },
    description: {
      defaultMessage:
        'Complete application management in one hub. Whether deleting apps and their associated data or keeping track of all important updates at all times, CleanMyMac takes the confusion out of your daily app management routine.',
    },
  }),
  clutter: defineMessages({
    title: {
      defaultMessage: 'Sorting of {accent} for peace of mind',
    },
    accent: {
      defaultMessage: 'duplicates',
    },
    description: {
      defaultMessage:
        'Delete duplicate files that are consuming valuable space. Detects large numbers of similar images, videos, and documents and processes them as desired. Work that used to take hours can be completed in minutes.',
    },
  }),
};

const featureData = [
  {
    id: 1,
    accentColor: '#3EAB40',
    isReverse: true,
    imgSrc: 'images/features/cleanup.jpg',
    messages: featureMessages.cleanup,
  },
  {
    id: 2,
    accentColor: '#FF710A',
    isReverse: false,
    imgSrc: 'images/features/performance.jpg',
    messages: featureMessages.performance,
  },
  {
    id: 3,
    accentColor: '#FB35B5',
    isReverse: true,
    imgSrc: 'images/features/protection.jpg',
    messages: featureMessages.protection,
  },
  {
    id: 4,
    accentColor: '#0D90EF',
    isReverse: false,
    imgSrc: 'images/features/applications.jpg',
    messages: featureMessages.applications,
  },
  {
    id: 5,
    accentColor: '#0EA4AE',
    isReverse: true,
    imgSrc: 'images/features/clutter.jpg',
    messages: featureMessages.clutter,
  },
];

const Features = (): ReactElement => (
  <section className="mb-15 px-4 md:mb-36">
    <div className="mx-auto max-w-screen-lg space-y-15 md:space-y-24">
      {featureData.map((feature) => (
        <Feature key={feature.id} feature={feature} />
      ))}
    </div>
  </section>
);

export { Features };
