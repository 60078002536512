import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import CheckBox from '@/icons/checkbox.svg';

const messages = defineMessages({
  title: {
    defaultMessage:
      'Enjoy smooth performance and smart optimization with Smart Care scans that do 5 things. Now you can focus on what really matters!',
  },
  description: {
    defaultMessage:
      'As the best all-in-one maintenance app for your Mac, CleanMyMac addresses all performance issues without interfering with your work or productivity.',
  },
  maintenance: {
    defaultMessage: 'maintenance',
  },
  freeSpace: {
    defaultMessage: 'free space (on a disk)',
  },
  antivirus: {
    defaultMessage: 'anti-virus',
  },
  performance: {
    defaultMessage: 'performance',
  },
  update: {
    defaultMessage: 'update',
  },
});

const listItems = [
  { key: 1, message: messages.maintenance },
  { key: 2, message: messages.freeSpace },
  { key: 3, message: messages.antivirus },
  { key: 4, message: messages.performance },
  { key: 5, message: messages.update },
];

const SmartCare = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-15 text-white md:mb-36 md:px-4">
      <div className="mx-auto flex max-w-screen-lg flex-col bg-[#B266E2] px-5 pb-15 pt-10 md:flex-row md:rounded-b-3xl md:px-10 md:pb-24 md:pt-20 lg:px-20">
        <div className="max-w-[560px] md:mr-16 lg:mr-24">
          <p className="mb-10 text-2xl font-semibold md:text-[32px]">{formatMessage(messages.title)}</p>
          <p className="mb-8 font-medium opacity-80 md:mb-0 md:max-w-[475px]">{formatMessage(messages.description)}</p>
        </div>
        <ul className="flex-shrink-0 space-y-3 md:space-y-6">
          {listItems.map(({ key, message }) => (
            <li key={key} className={`flex items-center`}>
              <CheckBox className="mr-3 md:mr-4 md:w-8" />
              <span className="md:text-xl">{formatMessage(message)}</span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export { SmartCare };
