import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { SubscriptionForm } from '../subscription-form';

const messages = defineMessages({
  title: {
    defaultMessage: 'Get the latest information first!',
  },
  text: {
    defaultMessage: 'Get expert advice and exclusive deals delivered straight to your inbox.',
  },
});

const SubscribeBanner = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mx-auto mb-9 w-full max-w-96 px-5 md:flex md:max-w-290 md:items-start md:justify-evenly lg:justify-start">
      <div className="mb-8 max-w-96 md:mr-10 lg:mr-[13%] lg:max-w-max">
        <p className="mb-10 text-[32px] font-semibold leading-tight md:mb-4 lg:text-[40px]">
          {formatMessage(messages.title)}
        </p>
        <p className="max-w-80 font-medium opacity-50">{formatMessage(messages.text)}</p>
      </div>
      <SubscriptionForm formName="subscription_main" />
    </section>
  );
};

export { SubscribeBanner };
