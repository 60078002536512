import { useIntl } from 'react-intl';

import OptimizedImage from '@/components/optimized-image';

function RealTimeMonitor() {
  const { formatMessage } = useIntl();

  return (
    <section className="mx-auto mb-15 w-full max-w-screen-lg px-5">
      <div className="rounded-3xl bg-[#f1f1f3] p-5 pb-14 md:flex md:items-start md:gap-20 md:p-10 lg:items-center lg:gap-24 lg:py-16">
        <OptimizedImage
          className="mb-10 w-full overflow-hidden rounded-xl md:m-0 md:w-auto md:flex-shrink-0 lg:hidden"
          src="images/monitor-mobile.jpg"
          width="280"
          height="201"
          alt=""
        />

        <OptimizedImage
          className="hidden rounded-xl lg:-ml-20 lg:block"
          src="images/monitor-desktop.jpg"
          width="575"
          height="433"
          alt=""
        />

        <div className="lg:flex lg:max-w-80 lg:flex-col lg:justify-center">
          <p className="mb-3 text-xl font-semibold leading-7 text-black lg:mb-3 lg:mt-8">
            {formatMessage({ defaultMessage: 'Display real-time Mac data in the menubar' })}
          </p>

          <p className="text-base font-normal leading-normal text-black">
            {formatMessage({
              defaultMessage:
                "Monitor all your Mac needs and perform useful operations without having to launch an app The CleanMyMac menu dashboard displays real-time information about your Mac's status score, CPU load, battery status, security status, and more. You can force close unresponsive apps, delete in the Trash, measure Wi-Fi speed, and free up memory instantly.",
            })}
          </p>
        </div>
      </div>
    </section>
  );
}

export { RealTimeMonitor };
