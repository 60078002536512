import { useState, FormEvent, useEffect, useRef } from 'react';
import { useIntersectionObserver } from '@/hooks/use-intersection-observer';
import { useAnalytics } from '../use-analytics';
import { analyticalData } from '@/config/analytics';
import { isEmailValid, isInputFilled } from '@/helpers/inputValidations';
import { FORM_PROXY_ID, FORM_PROXY_SUBSCRIBE_ENDPOINT } from '@/config/global';

type InputErrorType = 'noerror' | 'empty' | 'invalid';
type FormStateType = 'initial' | 'submitting' | 'success' | 'error';

type UseSubscriptionFormProps = {
  location: 'on_page_form_home';
  onSuccess?: () => void;
  onError?: () => void;
  eventAction: string;
};

const useSubscriptionForm = (props: UseSubscriptionFormProps) => {
  const { location, eventAction } = props;
  const [inputError, setInputError] = useState<InputErrorType>('noerror');
  const [formState, setFormState] = useState<FormStateType>('initial');
  const [email, setEmail] = useState<string>('');
  const [isFormShown, setIsFormShown] = useState<boolean>(false);

  const formRef = useRef<HTMLDivElement | null>(null);
  const { isIntersecting } = useIntersectionObserver(formRef, {}, true);
  const { sendEvent } = useAnalytics();

  useEffect(() => {
    if (isIntersecting && !isFormShown) {
      sendEvent({
        eventAction,
        eventCategory: analyticalData.eventCategory,
        eventLabel: analyticalData.eventLabel.form.impression,
        eventNonInteraction: true,
      });

      setIsFormShown(true);
    }
  }, [isIntersecting, sendEvent, eventAction, isFormShown]);

  const handleFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isInputFilled(email)) {
      setInputError('empty');
      sendEvent({
        eventAction,
        eventCategory: analyticalData.eventCategory,
        eventLabel: analyticalData.eventLabel.input.empty,
      });

      return;
    }

    if (!isEmailValid(email)) {
      setInputError('invalid');
      sendEvent({
        eventAction,
        eventCategory: analyticalData.eventCategory,
        eventLabel: analyticalData.eventLabel.input.invalid,
      });

      return;
    }

    const payload = {
      email,
      location,
      source: 'cmm_site_ja',
      product: 'cleanmymac-5',
    };

    setFormState('submitting');

    try {
      const response = await fetch(FORM_PROXY_SUBSCRIBE_ENDPOINT, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Client-Id': FORM_PROXY_ID || '' },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setFormState('success');
        props?.onSuccess && props?.onSuccess();

        sendEvent({
          eventAction,
          eventCategory: analyticalData.eventCategory,
          eventLabel: analyticalData.eventLabel.form.success,
        });
      } else {
        setFormState('error');
        props?.onError && props?.onError();
      }
    } catch (error) {
      setFormState('error');
      props?.onError && props?.onError();
    }
  };

  return {
    inputError,
    setInputError,
    setEmail,
    email,
    formState,
    isBtnDisabled: formState === 'submitting' || inputError !== 'noerror',
    handleFormSubmit,
    formRef,
  };
};

export default useSubscriptionForm;
