import type { NextPage } from 'next';
import Head from 'next/head';
import { useIntl } from 'react-intl';

import Hero from '@/components/hero';
import Awards from '@/components/awards';

import { Introduce } from '@/components/introduce';
import { SmartCare } from '@/components/smart-care';
import { Reviews } from '@/components/reviews';
import { Achievements } from '@/components/achievements';
import { Tools } from '@/components/tools';
import { CompanyInfo } from '@/components/company-info';
import { SubscribeBanner } from '@/components/subscribe-banner';
import { Requirements } from '@/components/requirements';
import { Faq } from '@/components/faq';
import { About } from '@/components/about';
import { Statistics } from '@/components/statistics';
import { Features } from '@/components/features';
import { HealthMonitor } from '@/components/health-monitor';
import { RealTimeMonitor } from '@/components/real-time-monitor';
import { BlogBanner } from '@/components/blog-banner';

const Home: NextPage = () => {
  const { formatMessage } = useIntl();

  const seoTitle = formatMessage({ defaultMessage: 'Safe Mac cleaner for a high-performing Mac' });
  const seoDescription = formatMessage({
    defaultMessage: 'Try an all-in-one Mac cleaner that protects, optimizes, and cares for your Mac.',
  });

  return (
    <>
      <Head>
        <title>{seoTitle}</title>
        <meta property="og:title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <meta property="og:description" content={seoDescription} />
        <meta property="og:image" content="/images/og_image.jpg" />
      </Head>
      <Hero />
      <Achievements />
      <Introduce />
      <SmartCare />
      <Statistics />
      <Features />
      <HealthMonitor />
      <RealTimeMonitor />
      <Tools />
      <Awards />
      <Reviews />
      <About />
      <Faq />
      <BlogBanner isOnMainPage={true} />
      <Requirements />
      <SubscribeBanner />
      <CompanyInfo />
    </>
  );
};

export default Home;
