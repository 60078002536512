import { ChangeEvent, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { messages } from './messages';

import ErrorIcon from '@/icons/error-info.svg';
import PawIcon from '@/icons/paw-white.svg';
import useSubscriptionForm from '@/hooks/use-subscription-form';
import { links } from '@/config/links';

interface SubscriptionFormProps {
  formName: string;
}

const SubscriptionForm = (props: SubscriptionFormProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { formName } = props;

  const { inputError, setInputError, setEmail, formState, isBtnDisabled, handleFormSubmit, formRef } =
    useSubscriptionForm({
      location: 'on_page_form_home',
      eventAction: 'On-Page Subscribe Form',
    });

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputError('noerror');
    setEmail(event.target.value);
  };

  return (
    <div className="max-w-96 md:mt-6 lg:max-w-none" data-qa={formName} ref={formRef}>
      {(formState === 'initial' || formState === 'submitting') && (
        <form onSubmit={handleFormSubmit} noValidate>
          <div className="relative mb-8 lg:flex lg:items-center lg:justify-start">
            <label className="relative mb-10 block lg:static lg:mb-0 lg:mr-4">
              <input
                type="email"
                className="flex h-[54px] w-full items-center justify-start rounded-7.5 border-2 border-[#e6e6e6] bg-transparent pl-5 pr-2 leading-tight outline-none transition-all hover:border-[#808080] focus:border-black lg:min-w-[300px]"
                placeholder={formatMessage(messages.placeholder)}
                required
                style={{
                  ...(inputError !== 'noerror' && { borderColor: '#e13e3e' }),
                }}
                onChange={handleInputChange}
              ></input>
              <p
                className="invisible absolute flex h-8 max-w-full items-center justify-start text-xs text-[#e13e3e]"
                style={{
                  ...(inputError !== 'noerror' && { visibility: 'visible' }),
                }}
              >
                <ErrorIcon className="mr-1 flex-shrink-0" />
                {inputError !== 'noerror' && formatMessage(messages[inputError])}
              </p>
            </label>
            <button
              type="submit"
              className="flex h-[54px] w-full items-center justify-center rounded-7.5 bg-black px-5 font-semibold leading-tight text-white hover:bg-[#282828] lg:w-max lg:px-8"
              disabled={isBtnDisabled}
            >
              {formState === 'submitting' && <PawIcon className="circling-paw h-[22px] w-[22px]" />}
              {formState !== 'submitting' && formatMessage(messages.button)}
            </button>
          </div>
          <p className="text-center text-xs tracking-tighter opacity-50">
            <FormattedMessage
              {...messages.note}
              values={{
                policy: (
                  <a
                    className="underline hover:cursor-pointer hover:no-underline"
                    rel="noreferrer"
                    target="_blank"
                    href={links.policy.privacy}
                  >
                    {formatMessage({ defaultMessage: 'Privacy Policy' })}
                  </a>
                ),
              }}
            />
          </p>
        </form>
      )}
      {formState === 'success' && (
        <div>
          <p className="mb-2 text-2xl font-medium">{formatMessage(messages.thanks)}</p>
          <p className="text-sm font-medium opacity-50">{formatMessage(messages.checkEmail)}</p>
        </div>
      )}
      {formState === 'error' && (
        <div>
          <p className="mb-2 text-2xl font-medium">{formatMessage(messages.smthWrong)}</p>
          <p className="text-sm font-medium opacity-50">{formatMessage(messages.tryAgain)}</p>
        </div>
      )}
    </div>
  );
};

export { SubscriptionForm };
