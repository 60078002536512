import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useAnalytics } from '@/hooks/use-analytics';
import { analyticalData } from '@/config/analytics';
import { links } from '@/config/links';
import OptimizedImage from '@/components/optimized-image';

const messages = defineMessages({
  cleanMyMac: {
    defaultMessage: 'CleanMyMac',
  },
  notSimple: {
    defaultMessage: 'Not a simple Mac cleaner All-in-one Mac performance improvement tool',
  },
  smartMacCare: {
    defaultMessage:
      'A new era of smart Mac care is here: free your Mac from unwanted data, deep scan for viruses, and maintain peak performance with CleanMyMac.',
  },
  compatible: {
    defaultMessage: 'Compatible with all Mac chips and the latest macOS',
  },
  download: {
    defaultMessage: 'Free download',
  },
  buyNow: {
    defaultMessage: 'Buy now',
  },
});

function Hero(): ReactElement {
  const { formatMessage } = useIntl();
  const { sendEvent } = useAnalytics();

  const handleBuyNowClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onBuyNowClick,
      eventLabel: analyticalData.eventLabel.topper,
    });
  };

  const handleDownloadClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onDownloadClick,
      eventLabel: analyticalData.eventLabel.topper,
    });
  };

  return (
    <section className="mb-10 px-4 md:mb-8">
      <div className="mx-auto flex max-w-screen-lg flex-col items-center lg:flex-row">
        <OptimizedImage
          className="mb-5 block rounded-4xl lg:mb-0 lg:mr-16"
          src="images/main-hero.jpg"
          width="576"
          height="400"
          alt=""
        />
        <div className="max-w-[576px] lg:pr-5">
          <h1 className="mb-4 rounded-7.5 text-8 font-semibold leading-10">{formatMessage(messages.cleanMyMac)}</h1>
          <h2 className="mb-4 text-2xl font-semibold leading-8 lg:pr-24">{formatMessage(messages.notSimple)}</h2>
          <p className="mb-4 lg:pr-14">{formatMessage(messages.smartMacCare)}</p>
          <p className="mb-10 text-sm opacity-50">{formatMessage(messages.compatible)}</p>
          <nav>
            <ul className="flex flex-col px-5 md:px-0 lg:flex-row">
              <li className="mb-5 lg:mb-0 lg:mr-8">
                <a
                  href={links.cmmDownload}
                  className="btn-new btn-new--primary btn-new--primary-outline"
                  target="_blank"
                  rel="noreferrer"
                  data-qa="free-download-topper"
                  onClick={handleDownloadClick}
                >
                  {formatMessage(messages.download)}
                </a>
              </li>
              <li>
                <a
                  href={links.cmmStore}
                  className="btn-new btn-new--secondary btn-new--secondary-outline"
                  target="_blank"
                  rel="noreferrer"
                  data-qa="buy-now-topper"
                  onClick={handleBuyNowClick}
                >
                  {formatMessage(messages.buyNow)}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

export default Hero;
