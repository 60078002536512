const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const isEmailValid = (email: string) => {
  return emailRegex.test(email);
};

const isInputFilled = (value: string) => {
  const trimmedValue = value.trim();

  return !!trimmedValue;
};

export { isEmailValid, isInputFilled };
