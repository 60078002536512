import { defineMessages } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    defaultMessage: 'Enter your email address…',
  },
  button: {
    defaultMessage: 'Register',
  },
  note: {
    defaultMessage: 'You can unsubscribe at any time - for more information, see our {policy}.',
  },
  invalid: {
    defaultMessage: 'Email address is not valid',
  },
  empty: {
    defaultMessage: 'Please fill in this field',
  },
  thanks: {
    defaultMessage: 'Thank you for subscribing!',
  },
  checkEmail: {
    defaultMessage: "You're almost there, check your email!",
  },
  smthWrong: {
    defaultMessage: 'Oops! Something went wrong.',
  },
  tryAgain: {
    defaultMessage: 'Please try again later.',
  },
});

export { messages };
