import { FormattedMessage } from 'react-intl';

function CompanyInfo() {
  return (
    <section className="border-b-[1px] border-[#ebebeb] bg-white">
      <div className="mx-auto w-full max-w-screen-lg px-5 pb-10 pt-15 md:flex md:justify-evenly lg:justify-between lg:gap-32 lg:py-24">
        <div className="mb-10 max-w-60 md:mb-0">
          <p className="mb-3 text-xl font-semibold text-black">
            <FormattedMessage defaultMessage="MacPaw's Office" />
          </p>

          <p className="max-w-80 text-base leading-snug text-black opacity-50">
            <FormattedMessage defaultMessage="Our team, rooted in Ukraine, is now spread all over the world, providing great products and services from everywhere." />
          </p>
        </div>

        <ul className="lg:mt-9 lg:flex lg:gap-10">
          <li className="mb-5 w-full max-w-48 lg:mb-0">
            <p className="text-sm font-normal leading-tight text-black">
              <FormattedMessage defaultMessage="USA" />
            </p>
            <p className="text-sm font-normal leading-6 text-black/50">
              <FormattedMessage
                defaultMessage="10 Canal Park, Suite 201,{br} Cambridge, MA 02141{br} 電話番号：+1 (877) 562 - 2729"
                values={{
                  br: <br />,
                }}
              />
            </p>
          </li>

          <li className="mb-5 w-full max-w-52 lg:mb-0">
            <p className="text-sm font-normal leading-tight text-black">
              <FormattedMessage defaultMessage="Ukraine" />
            </p>

            <p className="text-sm font-normal leading-6 text-black/50">
              <FormattedMessage
                defaultMessage="Velyka Vasylkisvska Street, 100 Kyiv, Kyivska oblast{br} Ukraine, 03150"
                values={{
                  br: <br />,
                }}
              />
            </p>
          </li>

          <li className="w-full max-w-52">
            <p className="text-sm font-normal leading-tight text-black">
              <FormattedMessage defaultMessage="Cyprus" />
            </p>

            <p className="text-sm font-normal leading-6 text-black/50">
              <FormattedMessage
                defaultMessage="6 Maximou Michailidi Str, Maximos Plaza Tower 3,{br} 1st floor, Flat/Office 101{br} 3106, Limassol, Cyprus"
                values={{
                  br: <br />,
                }}
              />
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export { CompanyInfo };
