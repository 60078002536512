import OptimizedImage from '@/components/optimized-image';
import { useAnalytics } from '@/hooks/use-analytics';
import { analyticalData } from '@/config/analytics';
import { links } from '@/config/links';
import { useIntl, FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
  macCare: {
    defaultMessage: 'Bring the {macCare} revolution to your devices',
  },
  tryTools: {
    defaultMessage: 'Try over 20 tools for free',
  },
  download: {
    defaultMessage: 'Free download',
  },
});

const Tools = () => {
  const { formatMessage } = useIntl();
  const { sendEvent } = useAnalytics();

  const handleDownloadClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onDownloadClick,
      eventLabel: analyticalData.eventLabel.middleSecond,
    });
  };

  return (
    <>
      <p className="mx-auto max-w-[490px] px-8 text-center text-3xl font-semibold leading-tight md:px-1 lg:text-10">
        <FormattedMessage
          {...messages.macCare}
          values={{
            macCare: <span className="text-[#FB35B5]">{formatMessage({ defaultMessage: 'Mac care' })}</span>,
          }}
        />
      </p>
      <div className="relative w-full overflow-hidden py-[88px] lg:pb-36 lg:pt-[72px]">
        <div className="relative left-1/2 mb-[88px] flex w-max -translate-x-1/2 transform lg:mb-[65px]">
          <OptimizedImage
            className="tools-item inline-block h-12 w-auto lg:h-20"
            src="/images/tools-icons/tools.jpg"
            width="5360"
            height="80"
          />

          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            <OptimizedImage src="/images/cmm5_logo_160.png" width="160" height="160" />
          </div>
        </div>

        <p className="mx-5 mb-8 text-center text-lg font-semibold leading-tight lg:text-xl">
          {formatMessage(messages.tryTools)}
        </p>
        <a
          href={links.cmmDownload}
          className="btn-new btn-new--primary btn-new--primary-outline mx-10 sm:mx-auto sm:max-w-48"
          target="_blank"
          rel="noreferrer"
          data-qa="free-download-middle-second"
          onClick={handleDownloadClick}
        >
          {formatMessage(messages.download)}
        </a>
      </div>
    </>
  );
};

export { Tools };
