import { useIntl } from 'react-intl';

import OptimizedImage from '@/components/optimized-image';
import { useAnalytics } from '@/hooks/use-analytics';

import { links } from '@/config/links';
import { useEffect, useRef, useState } from 'react';
import { useIntersectionObserver } from '@/hooks/use-intersection-observer';
import clsx from 'clsx';
import { analyticalData } from '@/config/analytics';

interface BlogBannerProps {
  isOnMainPage?: boolean;
}

function BlogBanner(props: BlogBannerProps) {
  const { isOnMainPage } = props;

  const bannerRef = useRef<HTMLDivElement>(null);
  const [isViewAnalyticsSent, setIsViewAnalyticsSent] = useState(false);

  const { formatMessage } = useIntl();
  const { sendEvent } = useAnalytics();
  const { isIntersecting } = useIntersectionObserver(bannerRef, {}, true);
  const eventLabel = isOnMainPage ? analyticalData.eventLabel.closer : analyticalData.eventLabel.blog.bottomBanner;

  useEffect(() => {
    // TODO: refactor this after useIntersectionObserver is refactored. See more comments in hook
    if (!isIntersecting || isViewAnalyticsSent) {
      return;
    }

    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onDownloadShow,
      eventLabel,
      eventNonInteraction: true,
    });

    setIsViewAnalyticsSent(true);
  }, [isIntersecting, isViewAnalyticsSent, sendEvent, eventLabel]);

  const handleDownloadClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onDownloadClick,
      eventLabel,
    });
  };

  const handleBuyNowClick = () => {
    sendEvent({
      eventCategory: analyticalData.eventCategory,
      eventAction: analyticalData.eventAction.onBuyNowClick,
      eventLabel,
    });
  };

  const containerClassnames = clsx('mx-auto px-5', {
    'max-w-screen-lg': !isOnMainPage,
    'max-w-290': isOnMainPage,
  });

  return (
    <section ref={bannerRef} className="mb-20 sm:mb-10">
      <div className={containerClassnames}>
        <div className="lg:rounded-30 rounded-3xl bg-[#F2F2F4] px-5 pb-16 pt-14 text-center text-black lg:py-10 lg:pl-24 lg:pr-20">
          <div className="lg:flex">
            <div className="relative mx-auto max-w-[640px]">
              <OptimizedImage
                className="mx-auto mb-10 lg:m-0"
                src="/images/banners/bottom/device.png"
                alt=""
                width="640"
                height="293"
              />

              <div className="absolute left-2/4 top-[3%] h-[82%] w-full max-w-[57.6%] -translate-x-2/4 overflow-hidden rounded-[5px_5px_0_0]">
                <video
                  className="absolute left-2/4 top-0 z-[-1] h-auto max-h-full max-w-[138.5%] -translate-x-2/4 text-[0]"
                  poster="/images/banners/bottom/poster.jpg"
                  autoPlay
                  loop
                  muted
                  playsInline
                >
                  <source src="/video/cmm-banner.mp4" type="video/mp4" />
                  <OptimizedImage
                    className="mx-auto mb-10 lg:m-0"
                    src="/images/banners/bottom/poster.jpg"
                    alt=""
                    width="500"
                    height="246"
                  />
                </video>
              </div>
            </div>

            <div className="lg:w-1/2 lg:max-w-96 lg:pl-4">
              <p
                className="mx-auto mb-8 max-w-sm text-left text-xl font-semibold leading-7 text-black lg:mx-0 lg:max-w-[264px] lg:pl-1 lg:pt-11"
                dangerouslySetInnerHTML={{
                  __html: formatMessage(
                    { defaultMessage: 'CleanMyMac {br} Easy Mac maintenance in one place' },
                    { br: '</br>' }
                  ),
                }}
              />

              <div className="mx-auto flex max-w-sm flex-col space-y-5 lg:m-0 lg:mb-6 lg:max-w-full lg:flex-row lg:space-x-5 lg:space-y-0">
                <a
                  href={links.cmmStore}
                  onClick={handleBuyNowClick}
                  target="_blank"
                  rel="noreferrer"
                  className="btn-new btn-new--primary btn-new--primary-outline min-w-0"
                  data-qa={isOnMainPage ? 'buy-now-closer' : 'buy-now-blog-banner'}
                >
                  {formatMessage({ defaultMessage: 'Buy now' })}
                </a>
                <a
                  href={links.cmmDownload}
                  onClick={handleDownloadClick}
                  rel="noreferrer"
                  target="_blank"
                  className="min-h-14 min-w-48 rounded-5xl bg-white px-1 py-4 font-semibold transition-all hover:bg-neutral-300 active:bg-neutral-200 lg:ml-5"
                  data-qa={isOnMainPage ? 'free-download-closer' : 'free-download-blog-banner'}
                >
                  {formatMessage({ defaultMessage: 'Free download' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export { BlogBanner };
