import { RefObject, useEffect, useState } from 'react';

interface UseIntersectionObserverOptions {
  root?: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
}

export const useIntersectionObserver = (
  ref: RefObject<Element>,
  options: UseIntersectionObserverOptions = {},
  disconnectOnIntersect: boolean = false
) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const currentElement = ref.current;

    if (!currentElement) {
      return;
    }

    const obs = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsIntersecting(entry.isIntersecting);

        if (disconnectOnIntersect) {
          obs.disconnect();
        }
      }
    }, options);

    obs.observe(currentElement);

    /* TODO refactoring: implement unsubscribe function and add possibility to disconnect observer manually outside of the hook
     *   these changes will allow to use this hook in more complex scenarios and remove if statements */
    return () => {
      obs.disconnect();
    };
  }, [ref, options, disconnectOnIntersect]);

  return { isIntersecting };
};
