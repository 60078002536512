import { ReactElement } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

const messages = defineMessages({
  title: {
    defaultMessage: 'Monitoring of Mac status scores',
  },
  description: {
    defaultMessage:
      'Use the Mac Assistant to view the invisible parts of your Mac. It monitors critical areas and provides unique tips to prevent future slowdowns and disk overloads.',
  },
});

const HealthMonitor = (): ReactElement => {
  return (
    <section className="mx-auto mb-15 text-center md:mb-24">
      <div className="max-w-screen-lg">
        <div className="px-5 md:px-4">
          <h3 className="mb-4 text-8 font-semibold text-[#9B4DE6] sm:text-10 md:mb-8">
            <FormattedMessage {...messages.title} />
          </h3>
          <p className="mx-auto mb-7.5 max-w-[692px] font-medium md:mb-10">
            <FormattedMessage {...messages.description} />
          </p>
          <OptimizedImage
            className="hidden sm:block"
            src="/images/health-monitor/health-monitor.jpg"
            width="1120"
            height="580"
          />
        </div>
        <OptimizedImage
          className="w-full sm:hidden"
          src="/images/health-monitor/health-monitor-mobile.jpg"
          width="360"
          height="212"
        />
      </div>
    </section>
  );
};

export { HealthMonitor };
