import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import Apple from '@/icons/apple.svg';
import OptimizedImage from '@/components/optimized-image';

const messages = defineMessages({
  appleNotarization: {
    defaultMessage: 'Apple Notarization',
  },
  years: {
    defaultMessage: '16',
  },
  moreThan: {
    defaultMessage: 'More than a year of experience',
  },
  avTestCertification: {
    defaultMessage: 'AV-TEST Certification',
  },
  evaluation: {
    defaultMessage: '4.7/5 evaluation',
  },
  millions: {
    defaultMessage: '29 million',
  },
  downloads: {
    defaultMessage: 'times download',
  },
});

const Achievements = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <div className="mx-auto mb-16 w-full max-w-screen-lg px-4 py-7 md:mb-32">
      <ul className="mx-auto flex max-w-80 flex-wrap items-center justify-center gap-8 md:max-w-full md:justify-between md:gap-0">
        <li className="flex items-center">
          <Apple className="mr-4" />
          <p className="text-sm font-medium">{formatMessage(messages.appleNotarization)}</p>
        </li>

        <li className="flex items-center">
          <p className="mr-3 text-3xl font-semibold">{formatMessage(messages.years)}</p>
          <p className="text-sm font-medium">{formatMessage(messages.moreThan)}</p>
        </li>

        <li className="flex items-center">
          <OptimizedImage className="mr-4" src="/images/achievements/avtest.jpg" width="32" height="36" alt="" />
          <p className="max-w-16 text-sm font-medium">{formatMessage(messages.avTestCertification)}</p>
        </li>

        <li className="flex items-center">
          <OptimizedImage className="mr-4" src="/images/achievements/appstore.jpg" width="36" height="36" alt="" />
          <p className="max-w-8 text-sm font-medium">{formatMessage(messages.evaluation)}</p>
        </li>

        <li className="flex items-center">
          <p className="mr-3 text-3xl font-semibold">{formatMessage(messages.millions)}</p>
          <p className="text-sm font-medium">{formatMessage(messages.downloads)}</p>
        </li>
      </ul>
    </div>
  );
};

export { Achievements };
