import { ReactElement } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

const messages = defineMessages({
  title: {
    defaultMessage: 'Trusted by many Japanese users',
  },
  review1: {
    defaultMessage:
      'As an amateur, I find this app very useful as it provides comprehensive support for caring for my Mac.',
  },
  source1: {
    defaultMessage: 'Here you go!',
  },
  review2: {
    defaultMessage:
      'The software itself is easy to use with just one touch, and you can also check the details of the folder you are deleting before deleting it.',
  },
  source2: {
    defaultMessage: 'A.C.S.T.H.K',
  },
  review3: {
    defaultMessage:
      "It's a good, lightweight and intuitive application that works well in my environment. I think it's worth the money.",
  },
  source3: {
    defaultMessage: 'zingy_jp',
  },
});

const Reviews = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-15 px-5 md:mb-24">
      <OptimizedImage className="mx-auto" src="images/review_img.png" width="180" height="120" alt="" />
      <p className="mb-10 text-center text-[32px] font-semibold md:mb-15 md:text-[40px]">
        {formatMessage(messages.title)}
      </p>

      <ul className="flex flex-wrap justify-center">
        <li className="mb-5 max-w-[352px] overflow-hidden rounded-5 bg-[#f2f2f4] p-8 sm:mb-8 md:mx-4 lg:mb-0 lg:mt-10">
          <p className="mb-20 text-xl">{formatMessage(messages.review1)}</p>
          <p className="text-sm font-semibold">{formatMessage(messages.source1)}</p>
        </li>
        <li className="mb-5 max-w-[352px] overflow-hidden rounded-5 bg-[#f2f2f4] p-8 sm:mb-8 md:mx-4 lg:mb-10">
          <p className="mb-20 text-xl">
            <FormattedMessage
              {...messages.review2}
              values={{
                br: <br />,
              }}
            />
          </p>
          <p className="text-sm font-semibold">{formatMessage(messages.source2)}</p>
        </li>
        <li className="max-w-[352px] overflow-hidden rounded-5 bg-[#f2f2f4] p-8 md:mx-4 lg:mb-0 lg:mt-10">
          <p className="mb-12 text-xl sm:mb-20">
            <FormattedMessage
              {...messages.review3}
              values={{
                br: <br />,
              }}
            />
          </p>
          <p className="text-sm font-semibold">{formatMessage(messages.source3)}</p>
        </li>
      </ul>
    </section>
  );
};

export { Reviews };
