/* @Hooks */
import { useIntl } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

function Awards() {
  const { formatMessage } = useIntl();

  return (
    <section className="mb-16 sm:mb-24">
      <div className="mx-auto max-w-screen-lg text-center sm:px-4">
        <p className="mb-10 px-5 text-3xl font-semibold">
          {formatMessage({ defaultMessage: 'Awarded Asian and International Awards' })}
        </p>
        <ul className="grid gap-x-4 gap-y-7 overflow-hidden bg-white py-10 xs:grid-cols-2 xs:gap-y-10 sm:rounded-7.5 sm:pb-20 sm:pt-14 md:grid-cols-3">
          <li>
            <OptimizedImage
              className="mx-auto"
              src="/images/awards/asia_design@2x.png"
              alt=""
              height="130"
              width="352"
            />
            <p className="px-8 text-base">{formatMessage({ defaultMessage: '2022 Asian Design Award: Gold Award' })}</p>
          </li>
          <li>
            <OptimizedImage className="mx-auto" src="/images/awards/red_dot@2x.png" alt="" height="130" width="352" />
            <p className="px-8 text-base lg:px-4">
              {formatMessage({ defaultMessage: 'Red Dot Design Award: 2021 Brand & Communication Design Category' })}
            </p>
          </li>
          <li>
            <OptimizedImage className="mx-auto" src="/images/awards/ux_award@2x.png" alt="" height="130" width="352" />
            <p className="px-8 text-base">
              {formatMessage({ defaultMessage: 'UX Design Award: 2021 Product Category' })}
            </p>
          </li>
          <li>
            <OptimizedImage className="mx-auto" src="/images/awards/IF@2x.png" alt="" height="130" width="352" />
            <p className="px-8 text-base lg:px-15">
              {formatMessage({ defaultMessage: 'iF Design Award: 2020 Communication Design Category' })}
            </p>
          </li>
          <li>
            <OptimizedImage
              className="mx-auto"
              src="/images/awards/product_hunt@2x.png"
              alt=""
              height="130"
              width="352"
            />
            <p className="px-8 text-base lg:px-4">
              {formatMessage({ defaultMessage: 'ProductHunt: 2018 "Best App of the Month"' })}
            </p>
          </li>
          <li>
            <OptimizedImage
              className="mx-auto"
              src="/images/awards/mac_observer@2x.png"
              alt=""
              height="130"
              width="352"
            />
            <p className="px-8 text-base lg:px-14">
              {formatMessage({ defaultMessage: `THE MACOBSERVER: 2013 Editor's Choice Award` })}
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Awards;
