import { ReactElement, useState } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { useAnalytics } from '@/hooks/use-analytics';
import { analyticalData } from '@/config/analytics';
import { links } from '@/config/links';
import ArrowUp from '@/icons/arrow-up.svg';

const messages = defineMessages({
  title: {
    defaultMessage: 'Q&A Corner',
  },
  question1: {
    defaultMessage: 'Is this paid software?',
  },
  answer1: {
    defaultMessage:
      'Yes, but we do offer a fully functional free version which you can {link} and which comes with a 30-day money back guarantee.',
  },
  question2: {
    defaultMessage: 'Is CleanMyMac safe to use?',
  },
  answer2: {
    defaultMessage:
      'CleanMyMac is Apple notarized as a virus-free application. Features like Safety Database, Smart Selection, and Exclusion List ensure that it only removes files that are 100% safe to delete.',
  },
  question3: {
    defaultMessage: 'How does this work?',
  },
  answer3: {
    defaultMessage:
      'We recommend you start with the Smart Care scan, which performs five main actions to deal with clutter, viruses, and other issues with one click, and then you can go through the individual features to perform various Mac optimization tasks depending on your needs.',
  },
});

const Faq = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mx-auto mb-15 max-w-[780px] px-5 leading-tight md:mb-32">
      <p className="mb-10 text-center text-[32px] font-semibold md:mb-15 md:text-[40px]">
        {formatMessage(messages.title)}
      </p>
      <FaqItem id={1} height={150} />
      <FaqItem id={2} height={200} />
      <FaqItem id={3} height={250} />
    </section>
  );
};

interface FaqItemProps {
  id: 1 | 2 | 3;
  height: number;
}

const FaqItem = (props: FaqItemProps): ReactElement => {
  const { id, height } = props;
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const { sendEvent } = useAnalytics();

  const answerStyle = {
    maxHeight: isOpen ? `${height}px` : 0,
    paddingBottom: isOpen ? '12px' : 0,
  };

  const arrowStyle = {
    transform: isOpen ? 'rotateX(0)' : 'rotateX(180deg)',
  };

  const handleFaqClick = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      sendEvent({
        eventAction: analyticalData.eventAction.whatAbout,
        eventCategory: analyticalData.eventCategory,
        eventLabel: analyticalData.eventLabel.faq[id],
      });
    }
  };

  return (
    <div
      className="mb-3 rounded-5 border-2 border-[rgba(0,0,0,0.08)] bg-white px-8 py-4 sm:rounded-7.5"
      data-qa="faq-item"
    >
      <button className="flex w-full items-center justify-between py-3" onClick={handleFaqClick}>
        <p className="mr-2 text-left font-semibold sm:text-xl">{formatMessage(messages[`question${id}`])}</p>
        <ArrowUp className="flex-shrink-0 transition-transform duration-300 ease-in-out" style={arrowStyle} />
      </button>
      <p className="overflow-hidden text-left opacity-70 transition-all duration-300 ease-in-out" style={answerStyle}>
        <FormattedMessage
          {...messages[`answer${id}`]}
          values={{
            link: (
              <a className="underline hover:no-underline" href={links.cmmDownload} target="_blank" rel="nofollow">
                {formatMessage({ defaultMessage: 'download here' })}
              </a>
            ),
          }}
        />
      </p>
    </div>
  );
};

export { Faq };
