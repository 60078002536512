import { ReactElement } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import RequirementsIcon from '@/icons/requirements.svg';
import RatingIcon from '@/icons/rating.svg';
import PriceIcon from '@/icons/price.svg';
import InfoIcon from '@/icons/info.svg';
import Stars from '@/icons/stars5.svg';

const messages = defineMessages({
  systemRequirements: {
    defaultMessage: 'System requirements:',
  },
  minDisplay: {
    defaultMessage: 'Minimum display size: {br}1200 pixels wide {br}by 800 pixels high',
  },
  macos: {
    defaultMessage: 'macOS 11+',
  },
  ratingTitle: {
    defaultMessage: 'Rating:',
  },
  rating: {
    defaultMessage: '/ 4.9*',
  },
  priceTitle: {
    defaultMessage: 'Price:',
  },
  price: {
    defaultMessage: 'Price: ¥4,388',
  },
  info: {
    defaultMessage: 'Latest version:',
  },
  version: {
    defaultMessage: '5.0.0 | October 16, 2024',
  },
  note: {
    defaultMessage: '*4.9 - Rated based on 539 reviews for all versions',
  },
});

const Requirements = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mx-auto mb-15 w-full max-w-290 px-5 md:mb-24">
      <div className="mb-4 w-full rounded-5 bg-[rgb(242,242,244)] px-5 pb-8 pt-6 text-center md:grid md:grid-cols-4 md:items-start md:px-5 md:py-12">
        <div className="requirements__item relative mb-4 flex h-full w-full flex-col items-center justify-center md:mb-0 md:justify-start md:pr-5">
          <RequirementsIcon className="mb-1 lg:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.systemRequirements)}</p>
          <p className="text-sm">{formatMessage(messages.macos)}</p>
          <p className="text-sm">
            {formatMessage(messages.minDisplay, {
              br: <br />,
            })}
          </p>
        </div>
        <div className="requirements__item relative mb-4 flex h-full w-full flex-col items-center justify-center md:mb-0 md:justify-start">
          <RatingIcon className="mb-1 lg:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.ratingTitle)}</p>
          <div className="flex items-center justify-center">
            <Stars />
            <p className="text-sm">{formatMessage(messages.rating)}</p>
          </div>
        </div>
        <div className="requirements__item relative mb-4 flex h-full w-full flex-col items-center justify-center md:mb-0 md:justify-start">
          <PriceIcon className="mb-1 lg:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.priceTitle)}</p>
          <p className="text-sm">{formatMessage(messages.price)}</p>
        </div>
        <div className="flex h-full w-full flex-col items-center justify-center md:justify-start">
          <InfoIcon className="mb-1 lg:mb-5" />
          <p className="mb-1 text-xs opacity-50">{formatMessage(messages.info)}</p>
          <p className="text-sm">{formatMessage(messages.version)}</p>
        </div>
      </div>
      <p className="text-center text-xs opacity-50">{formatMessage(messages.note)}</p>
    </section>
  );
};

export { Requirements };
