import { ReactElement } from 'react';
import OptimizedImage from '@/components/optimized-image';
import { useIntl, defineMessages } from 'react-intl';

const messages = defineMessages({
  takeCare: {
    defaultMessage: 'Take care of your Mac like you take care of your garden.',
  },
  likeGarden: {
    defaultMessage:
      'Like a garden that blooms if carefully tended, regular maintenance is necessary to keep your Mac performing at its best.',
  },
});

const Introduce = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="relative mx-auto text-white md:bg-transparent md:px-4">
      <div className="introduce__screencast relative">
        <div className="absolute left-0 right-0 overflow-hidden md:rounded-7.5">
          <OptimizedImage className="max-w-full" src="/images/back.jpg" width={1120} height={592} />
        </div>

        <div className="absolute left-[9%] right-[9%] top-[-9%] max-w-full overflow-hidden rounded-lg sm:left-[11%] sm:right-[11%] md:rounded-5">
          <video className="max-w-full" poster="/images/ph-video.jpg" autoPlay loop muted playsInline>
            <source src="/video/cmm-overview.mp4" type="video/mp4" />
            <img src="/images/ph-video.jpg" alt="" />
          </video>
        </div>

        <OptimizedImage className="relative max-w-full" src="/images/front.png" width={1120} height={860} />
      </div>

      <div className="mx-auto -mt-1 w-full bg-[#551283] px-5 pb-10 text-center md:absolute md:bottom-12 md:left-1/2 md:-translate-x-1/2 md:bg-transparent md:px-10 md:pb-0 lg:bottom-24 lg:px-20">
        <h3 className="relative -top-6 mx-auto mb-8 max-w-80 text-8 font-semibold sm:max-w-full md:text-10">
          {formatMessage(messages.takeCare)}
        </h3>

        <p className="relative -top-6 mx-auto max-w-80 text-lg opacity-70 sm:max-w-150 md:w-auto md:px-1 md:text-xl">
          {formatMessage(messages.likeGarden)}
        </p>
      </div>
    </section>
  );
};

export { Introduce };
