import { ReactElement } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import OptimizedImage from '@/components/optimized-image';

const messages = defineMessages({
  title: {
    defaultMessage: 'Developed by a Caring Company',
  },
  text: {
    defaultMessage:
      "MacPaw develops precision-engineered products used by over 30 million users worldwide. CleanMyMac reflects our passion for quality and innovation, but our impact goes beyond that. From launching a corporate charitable foundation to driving over 30 social initiatives, we're committed to making the world a better place.",
  },
  quote: {
    defaultMessage:
      '"Alongside our sincere commitment to trust, transparency, accountability and service excellence, we believe in the transformative power of technology and innovation."',
  },
  name: {
    defaultMessage: 'Oleksandr Kosovan',
  },
  occupation: {
    defaultMessage: 'Entrepreneur and CEO of MacPaw',
  },
});

const About = (): ReactElement => {
  const { formatMessage } = useIntl();

  return (
    <section className="mx-auto mb-15 max-w-[1160px] px-5 sm:grid sm:gap-8 lg:mb-24 lg:grid-cols-2">
      <div className="mb-5 max-w-[544px] rounded-5 bg-[#033A37] p-8 pb-28 text-[#C3E5D6] md:mb-0 md:p-15 md:pb-60">
        <p className="text-left text-[32px] font-semibold leading-tight lg:text-[40px]">
          {formatMessage(messages.title)}
        </p>
        <p className="mb-0 text-left font-medium">{formatMessage(messages.text)}</p>
      </div>
      <div className="mb-5 max-w-[544px] rounded-5 bg-[#D8EEE4] p-8 pb-10 md:mb-0 md:p-15 md:pb-11">
        <p className="mb-20 text-left text-xl font-medium text-[#033A37] lg:mb-20">{formatMessage(messages.quote)}</p>
        <OptimizedImage
          className="mb-5 ml-auto mr-0 max-w-44 rounded-xl md:max-w-none lg:mb-10"
          src="images/ceo.jpg"
          width="248"
          height="248"
          alt=""
        />
        <p className="mb-0 text-right text-sm font-bold">{formatMessage(messages.name)}</p>
        <p className="mb-0 text-right text-sm font-bold">{formatMessage(messages.occupation)}</p>
      </div>
    </section>
  );
};

export { About };
